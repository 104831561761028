import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Menu',
    isTitle: true
  },
  {
    id: 2,
    label: 'Dashboard',
    icon: 'ri-dashboard-2-line',
    link: '/',
    accesoId: 'DASHBOARD'
  },
  {
    id: 8,
    label: 'Configuraciones',
    icon: 'ri-settings-3-line',
    accesoId: 'CONFIGURACIONES',
    subItems: [
      {
        id: 9,
        label: 'Perfil Sociodemográfico',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Categoria Curso Períodico',
            link: '/configuraciones/categoria-curso',
          },
          {
            id: 9,
            label: 'Curso Períodico',
            link: '/configuraciones/curso-periodico',
          },
          {
            id: 9,
            label: 'Preguntas Adicionales',
            link: '/configuraciones/pregunta-adicional',
          }
        ]
      },
      {
        id: 100,
        label: 'Accidentes de Trabajo',
        subItems: [
          {
            id: 9,
            label: 'Tipo Evento',
            link: '/accidente-trabajo/tipo-evento',
          },
          {
            id: 9,
            label: 'Tipo Lesion',
            link: '/accidente-trabajo/tipo-lesion',
          },
          {
            id: 9,
            label: 'Partes Cuerpo',
            link: '/accidente-trabajo/partes-cuerpo',
          },
          {
            id: 9,
            label: 'Agente Accidente',
            link: '/accidente-trabajo/agente-accidente',
          },
          {
            id: 9,
            label: 'Mecanismo Forma Accidente',
            link: '/accidente-trabajo/mecanismo-forma-accidente',
          },
          {
            id: 9,
            label: 'Origen del Peligro',
            link: '/accidente-trabajo/origen-peligro',
          }
        ]
      },
      {
        id: 9,
        label: 'Enfermedad Laboral',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Diagnósticos',
            link: '/configuraciones/diagnostico',
          },
          {
            id: 9,
            label: 'Entidad Calificación',
            link: '/configuraciones/entidad-calificacion',
          }
        ]
      },
      {
        id: 9,
        label: 'Casos Médicos',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Diagnósticos',
            link: '/configuraciones/diagnostico',
          },
          {
            id: 9,
            label: 'Origén Caso',
            link: '/configuraciones/origen-caso',
          },
          {
            id: 9,
            label: 'Modalidad Reincorporación',
            link: '/configuraciones/modalidad-reincorporacion',
          }
        ]
      },
      {
        id: 9,
        label: 'Clientes',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/clientes',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/clientes/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Proyectos',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/proyectos',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/proyectos/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Sedes',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/sedes',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/sedes/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Cuadrillas',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/cuadrillas',
          },
          {
            id: 9,
            label: 'Nuevo',
            link: '/configuraciones/cuadrillas/nuevo',
          }
        ]
      },
      {
        id: 9,
        label: 'Afp',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/afp',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/afp/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Cargo',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/cargos',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/cargo/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Eps',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/eps',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/eps/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Estado Civil',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/estado-civil',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/estado-civil/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Grado Escolaridad',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/grado-escolaridad',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/grado-escolaridad/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Departamentos',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/departamentos',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/departamento/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Municipios',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/municipios',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/municipio/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Proceso',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/procesos',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/proceso/nuevo',
          // }
        ]
      },
      {
        id: 9,
        label: 'Tipo Vinculación',
        parentId: 8,
        subItems: [
          {
            id: 9,
            label: 'Listar',
            link: '/configuraciones/tipo-vinculacion',
          },
          // {
          //   id: 9,
          //   label: 'Nuevo',
          //   link: '/tipo-vinculacion/nuevo',
          // }
        ]
      }
    ]
  },
  {
    id: 2,
    label: 'Perfil Sociodemográfico',
    icon: 'ri-shield-user-line',
    accesoId: 'PERFIL_SOCIODEMOGRAFICO',
    subItems: [
      {
        id: 9,
        label: 'Listar',
        link: '/empleados',
      },
      // {
      //   id: 9,
      //   label: 'Perfil Empleado',
      //   link: '/empleados/perfiles',
      // }
    ]
  },
  {
    id: 2,
    label: 'Accidentes de Trabajo',
    icon: 'ri-hospital-line',
    accesoId: 'ACCIDENTE_TRABAJO',
    subItems: [
      // {
      //   id: 9,
      //   label: 'Dashboard',
      //   link: '/accidente-trabajo',
      // },
      {
        id: 9,
        label: 'Listar',
        link: '/accidente-trabajo/listar',
      },
      {
        id: 9,
        label: 'Nuevo',
        link: '/accidente-trabajo/nuevo',
      }
    ]
  },
  {
    id: 2,
    label: 'Plan de Trabajo',
    icon: 'ri-calendar-todo-line',
    accesoId: 'PLAN_TRABAJO',
    subItems: [
      {
        id: 9,
        label: 'Dashboard',
        link: '/plan-trabajo',
      },
      // {
      //   id: 9,
      //   label: 'Consultas',
      //   link: '/plan-trabajo/nuevo',
      // }
    ]
  },
  {
    id: 2,
    label: 'Plan de Capacitación',
    icon: 'ri-git-repository-line',
    accesoId: 'PLAN_CAPACITACION',
    subItems: [
      {
        id: 9,
        label: 'Dashboard',
        link: '/plan-capacitacion',
      },
      // {
      //   id: 9,
      //   label: 'Nuevo',
      //   link: '/accidente-trabajo/nuevo',
      // }
    ]
  },
  {
    id: 2,
    label: 'Enfermedad Laboral',
    icon: 'ri-home-heart-line',
    accesoId: 'ENFERMEDAD_LABORAL',
    subItems: [
      {
        id: 9,
        label: 'Listar',
        link: '/enfermedad-laboral/listar',
      },
      // {
      //   id: 9,
      //   label: 'Perfil Empleado',
      //   link: '/empleados/perfiles',
      // }
    ]
  },
  {
    id: 2,
    label: 'Casos Médicos',
    icon: 'ri-chat-follow-up-line',
    accesoId: 'CASOS_MEDICOS',
    subItems: [
      {
        id: 9,
        label: 'Listar',
        link: '/casos-medicos/listar',
      },
      // {
      //   id: 9,
      //   label: 'Perfil Empleado',
      //   link: '/empleados/perfiles',
      // }
    ]
  }
];
