import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalComponent } from '../../../global-component';
import { EditNotificacion, NotificacionAccidenteTrabajo, Notificaciones } from '../models/notificaciones.model';
import { GenericGetData } from "../../../core/models/get-data.model";

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  private urlService = 'notificaciones/';

  constructor(private http: HttpClient) { }

  getNotificacionesPlanTrabajo(page: number, pageSize: number, filter: string, empleadoId: string, leida: string) {
    let urlFilter = page === 0 || pageSize === 0 ? "" : `?pageNumber=${page}&pageSize=${pageSize}&filter=${filter}&empleadoSesionId=${empleadoId}&leida=${leida}`;
    return this.http.get<GenericGetData<Notificaciones[]>>(`${GlobalComponent.API_URL}${this.urlService}${"plan-trabajo/all"}${urlFilter}`, { reportProgress: true, observe: "events" });
  }

  marcarNotificacionPlanTrabajoLeida(data: EditNotificacion) {
    return this.http.post(`${GlobalComponent.API_URL}${this.urlService}${"plan-trabajo/leida"}`,data);
  }

  /* NOTIFICACIONES ACCIDENTE TRABAJO */
  getNotificacionesAccidenteTrabajo(empleadoId: string, leida: string) {
    let urlFilter = `?empleadoSesionId=${empleadoId}&leida=${leida}`;
    return this.http.get<GenericGetData<NotificacionAccidenteTrabajo[]>>(`${GlobalComponent.API_URL}${this.urlService}${"accidente-trabajo/all"}${urlFilter}`, { reportProgress: true, observe: "events" });
  }

  marcarNotificacionAccidenteTrabajoLeida(data: EditNotificacion) {
    return this.http.post(`${GlobalComponent.API_URL}${this.urlService}${"accidente-trabajo/leida"}`,data);
  }
}
