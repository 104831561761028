<header id="page-topbar" data-scroll-header (window:scroll)="windowScroll()">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a href="javascript:void(0);" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="17">
                        </span>
                    </a>

                    <a href="javascript:void(0);" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="17">
                        </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>
            </div>

            <div class="d-flex align-items-center">

                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-toggle="fullscreen" (click)="fullscreen()">
                        <i class='bx bx-fullscreen fs-22'></i>
                    </button>
                </div>

                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                        <i class='bx bx-moon fs-22' (click)="changeMode('dark')"></i>
                        <i class='bx bx-sun fs-22' (click)="changeMode('light')"></i>
                    </button>
                </div>

                <div ngbDropdown [autoClose]="false" class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button type="button" ngbDropdownToggle class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class='bx bx-bell fs-22'></i>
                        <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">!<span class="visually-hidden">unread messages</span></span>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">

                        <div class="dropdown-head bg-primary  bg-pattern rounded-top">
                            <div class="p-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="m-0 fs-16 fw-semibold text-white"> Notificaciones </h6>
                                    </div>
                                    <!-- <div class="col-auto dropdown-tabs">
                                        <span class="badge bg-light-subtle text-body fs-13"> {{this.countNotificaciones}} New</span>
                                    </div> -->
                                </div>
                            </div>

                            <div class="px-2 pt-2">
                                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs dropdown-tabs nav-tabs-custom" id="notificationItemsTab">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>
                                            Plan Trabajo
                                        </a>
                                        <ng-template ngbNavContent>
                                            <app-notificaciones-plantrabajo></app-notificaciones-plantrabajo>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink>
                                            Accidente Trabajo
                                        </a>
                                        <ng-template ngbNavContent>
                                            <app-notificaciones-accidentetrabajo></app-notificaciones-accidentetrabajo>
                                        </ng-template>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="tab-content" id="notificationItemsTabContent">
                            <div class="tab-content text-muted">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>

                        <div class="notification-actions" id="notification-actions">
                            <div class=" d-flex text-muted justify-content-center">
                                Select <div id="select-content" class="text-body fw-semibold px-1">{{checkedValGet.length}}</div> Result <button type="button" class="btn btn-link link-danger p-0 ms-3" data-bs-toggle="modal" (click)="openModal(removenotification)" data-bs-target="#removeNotificationModal">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                    <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="assets/images/users/user-dummy-img.jpg" alt="Avatar">
                            <span class="text-start ms-xl-2">
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{userData.displayName}}
                                    {{userData.last_name}}</span>
                                <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{userData.role}}</span>
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <!-- item-->
                        <h6 class="dropdown-header">Bienvenido!</h6>
                        <a class="dropdown-item" routerLink="/"><i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Mi perfil</span></a>
                        <a class="dropdown-item" routerLink="/"><i class="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Notificaciones y/o alertas</span></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span class="align-middle" data-key="t-logout">Cerrar Sesi&oacute;n</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- removeNotificationModal -->
<ng-template #removenotification let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="NotificationModalbtn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style="width:100px;height:100px"></lord-icon>
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you sure you want to remove this Notification ?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal" (click)="modal.dismiss('Cross click')">Close</button>
                <button type="button" class="btn w-sm btn-danger" id="delete-notification" (click)="notificationDelete()">Yes, Delete It!</button>
            </div>
        </div>

    </div><!-- /.modal-content -->

</ng-template>