import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EneconGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if (!sessionStorage.getItem("currentUser")) {
      this.router.navigate(["/auth/login"]);
      return true;
    }

    var user!: any;
    var currentUser = sessionStorage.getItem("currentUser");
    if (currentUser) {
      user = JSON.parse(currentUser);
    }

    if (user?.permissions.length > 0) {
      return true;
    }

    // let rolesRequeridos = route.data["roles"] as Array<string>;

    // for (let i = 0; i < rolesRequeridos.length; i++) {
    //   if (user?.permissions.includes(rolesRequeridos[i])) {
    //     return true;
    //   }
    // }

    this.router.navigate(["/auth/error-404"]);
    return false;
  }
}
