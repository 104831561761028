import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalComponent } from 'src/app/global-component';
import { VwActividadPlanTrabajo } from '../../plan-trabajo/models/actividad.model';
import { VwMisTemasCapacitacion } from '../../plan-capacitacion/models/tema-principal.model';
import { GetAllAccidenteTrabajoModel } from '../../accidente-trabajo/models/accidente-trabajo.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getAllActividadesByPlanTrabajo(empleadoId: any, startDate: string, endDate: string) {
    return this.http.get<VwActividadPlanTrabajo[]>(`${GlobalComponent.API_URL}dashboard/actividades?id=${empleadoId}&startDate=${startDate}&endDate=${endDate}`, { reportProgress: true, observe: "events" });
  }

  getMisTemasPrincipales(empleadoId: any, startDate: string, endDate: string) {
    return this.http.get<VwMisTemasCapacitacion[]>(`${GlobalComponent.API_URL}dashboard/temas?id=${empleadoId}&startDate=${startDate}&endDate=${endDate}`, { reportProgress: true, observe: "events" });
  }

  getAllAccidentesTrabajo(empleadoId: any,startDate: string, endDate: string) {
    return this.http.get<GetAllAccidenteTrabajoModel[]>(`${GlobalComponent.API_URL}dashboard/accidentes?id=${empleadoId}&startDate=${startDate}&endDate=${endDate}`, { reportProgress: true, observe: "events" });
  }
}
