import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AuthenticationService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('myModal') myModal!: ElementRef;
  listeningMouse!: boolean;
  idTimeOut!: number;
  idSetInterval!: any;
  checador: any;
  saliendo: boolean = false;
  progressValue: number = 0;

  constructor(private router: Router, private authService: AuthenticationService, private modalService: NgbModal) {

  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {

    let posicionX, posicionY, nuevaPosicionY, nuevaPosicionX;
    this.listeningMouse = true;

    nuevaPosicionX = event.clientX;
    nuevaPosicionY = event.clientY;

    if (
      this.listeningMouse &&
      (nuevaPosicionX !== posicionX || nuevaPosicionY !== posicionY)
    ) {
      clearTimeout(this.checador);
      this.checador = setTimeout(() => {
        if (this.saliendo == false) {
          this.cerrarSesion();
        }
      }, (1000 * 60 * 15)); //15 Mins
    }

    posicionX = event.clientX;
    posicionY = event.clientY;
  }

  cerrarSesion(): void {
    if (this.authService.getLoggedUser()?.userId && this.authService.getLoggedUser()?.userId !== '') {
      this.modalService.dismissAll();
      Swal.fire({
        html: `<img src="assets/images/logo-dark.png" alt="Enecon" height="20"> </br></br> 
        Hemos detectado inactividad en el sistema y procederemos a cerrar su sesión por seguridad.`,
        confirmButtonColor: "rgb(19, 38, 73)",
        confirmButtonText: "Aceptar",
      }).then(() => {
        this.authService.logout();
        this.router.navigate([`/auth/login`]);
      });
    }
  }

  ngOnDestroy() {
    this.listeningMouse = false;
    clearInterval(this.idSetInterval);
    clearInterval(this.idTimeOut);
    clearTimeout(this.checador);
    //this.onMouseMove = null;
  }

}
