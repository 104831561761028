import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Notificaciones, EditNotificacion } from 'src/app/pages/notificaciones/models/notificaciones.model';
import { NotificacionesService } from 'src/app/pages/notificaciones/services/notificaciones.service';

@Component({
  selector: 'app-notificaciones-plantrabajo',
  templateUrl: './notificaciones-plantrabajo.component.html',
  styleUrls: ['./notificaciones-plantrabajo.component.css']
})
export class NotificacionesPlantrabajoComponent implements OnInit {
  userData: any;
  notificaciones: Notificaciones[] = [];
  countNotificaciones: Number = 0;
  editNotificacion!: EditNotificacion;

  notificacionSeleccionada!: Notificaciones;

  constructor(private authService: AuthenticationService, public modalService: NgbModal,
    private router: Router, private tokenStorageService: TokenStorageService, 
    private notificacionesService: NotificacionesService) { }

  ngOnInit() {
    this.userData = this.tokenStorageService.getUser();
    this.getNotificacionesData(this.userData.empleadoId);
  }

  getNotificacionesData(empleadoId: string) {
    this.notificacionesService.getNotificacionesPlanTrabajo(1, 100, '', empleadoId, 'N').subscribe({
      next: (event) => {
        if (event.type === HttpEventType.Response && event.body) {
          this.notificaciones = event.body.data;
          this.countNotificaciones = event.body.total;
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err.error)
      },
    });
  }

  tiempoTranscurrido(desdeFecha: string): string {
    const ahora = new Date(); // Fecha actual
    const fecha = new Date(desdeFecha);
    // Diferencia en milisegundos
    const diferenciaMs = ahora.getTime() - fecha.getTime();

    // Cálculos precisos
    const minutos = Math.floor(diferenciaMs / (1000 * 60)); // Milisegundos a minutos
    const horas = Math.floor(diferenciaMs / (1000 * 60 * 60)); // Milisegundos a horas
    const dias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24)); // Milisegundos a días
    const semanas = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24 * 7)); // Milisegundos a semanas

    // Retornar solo el valor correspondiente según la diferencia
    if (minutos < 60) {
      return `hace ${minutos} minuto${minutos !== 1 ? 's' : ''}`;
    } else if (horas < 24) {
      return `hace ${horas} hora${horas !== 1 ? 's' : ''}`;
    } else if (dias < 7) {
      return `hace ${dias} día${dias !== 1 ? 's' : ''}`;
    } else {
      return `hace ${semanas} semana${semanas !== 1 ? 's' : ''}`;
    }
  }

  irDetalle(idnotificacion: string, tipoActividad: string) {
    this.router.navigate([`plan-trabajo/detalle-actividad/${idnotificacion}/${tipoActividad}`]);
  }

  marcarLeida(notificacionPlanTrabajoId: string) {

    this.editNotificacion = {
      notificacionId: notificacionPlanTrabajoId
    }

    this.notificacionesService.marcarNotificacionPlanTrabajoLeida(this.editNotificacion).subscribe({
      next: (event) => {
        this.getNotificacionesData(this.userData.empleadoId);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err.error)
      },
    });
    
  }

  openModalDetalleActividad(content: any, notificacion: Notificaciones) {
    this.modalService.open(content, { size: 'md', centered: true });
    this.notificacionSeleccionada = notificacion;
  }

}
