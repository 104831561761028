import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Component pages
import { DashboardComponent } from "./dashboards/dashboard/dashboard.component";
import { EneconGuard } from '../core/guards/enecon-gsig.guard';

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [EneconGuard],
  },
  {
    path: '', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
  },
  {
    path: 'empleados', loadChildren: () => import('./empleados/empleados.module').then(m => m.EmpleadosModule)
  },
  {
    path: 'configuraciones', loadChildren: () => import('./configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule)
  },
  {
    path: 'accidente-trabajo', loadChildren: () => import('./accidente-trabajo/accidente-trabajo.module').then(m => m.AccidenteTrabajoModule)
  },
  {
    path: 'plan-trabajo', loadChildren: () => import('./plan-trabajo/plan-trabajo.module').then(m => m.PlanTrabajoModule)
  },
  {
    path: 'plan-capacitacion', loadChildren: () => import('./plan-capacitacion/plan-capacitacion.module').then(m => m.PlanCapacitacionModule)
  },
  {
    path: 'notificaciones', loadChildren: () => import('./notificaciones/notificaciones.module').then(m => m.NotificacionesModule)
  },
  {
    path: 'enfermedad-laboral', loadChildren: () => import('./enfermedad-laboral/enfermedad-laboral.module').then(m => m.EnfermedadLaboralModule)
  },
  {
    path: 'casos-medicos', loadChildren: () => import('./casos-medicos/casos-medicos.module').then(m => m.CasosMedicosModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
