<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Inicio" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
    <div class="col-md-8">
        <div class="card card-h-100">
            <div class="card-body">
                <full-calendar [options]="calendarOptions"></full-calendar>
                <!-- <div id="calendar"></div> -->
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <div class="row">
            <div class="col-md-6">
                <div class="card-body">
                    <div class="row">
                        <h6 class="text-uppercase fw-semibold mt-4 mb-3 text-muted">Actividades Pendientes</h6>
                        @for (item of vwActividades; track $index) {
                        <div class="mini-stats-wid d-flex align-items-center mt-3">
                            <div class="flex-shrink-0 avatar-sm">
                                <span
                                    class="mini-stat-icon avatar-title rounded-circle text-primary bg-primary-subtle fs-4">
                                    {{ item.fechaInicio | date: 'dd' }}
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="mb-1">{{ item.titulo }}</h6>
                                <p class="text-muted mb-0">{{ item.fechaInicio | date: 'dd/MM/yyyy' }}</p>
                            </div>
                        </div>
                        }
                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <div class="card-body">
                    <div class="row">
                        <h6 class="text-uppercase fw-semibold mt-4 mb-3 text-muted">Capacitaciones Pendientes</h6>
                        @for (item of vwTemas; track $index) {
                        <div class="mini-stats-wid d-flex align-items-center mt-3">
                            <div class="flex-shrink-0 avatar-sm">
                                <span
                                    class="mini-stat-icon avatar-title rounded-circle text-success bg-success-subtle fs-4">
                                    {{ item.fechaInicio | date: 'dd' }}
                                </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="mb-1">{{ item.titulo }}</h6>
                                <p class="text-muted mb-0">{{ item.fechaInicio | date: 'dd/MM/yyyy' }}</p>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>

        @if (authService.canViewOption('CERRAR_AT')) {
        <div class="row mt-3">
            <div class="card-body">
                <div class="row">
                    <h6 class="text-uppercase fw-semibold mt-4 mb-3 text-muted">Accidentes de Trabajo</h6>
                    @for (item of vwAccidentes; track $index) {

                    <div class="mini-stats-wid d-flex align-items-center mt-3">
                        <div class="flex-shrink-0 avatar-sm">
                            <span
                                class="mini-stat-icon avatar-title rounded-circle text-danger bg-danger-subtle fs-4">
                                {{ item.fechaAccidente | date: 'dd' }}
                            </span>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h6 class="mb-1">{{ item.nombreEmpleado }}</h6>
                            <p class="text-muted mb-0">{{ item.fechaAccidente | date: 'dd/MM/yyyy' }}</p>
                        </div>
                    </div>

                    }
                </div>
            </div>
        </div>
        }

    </div>

</div>