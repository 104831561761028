import { Injectable } from '@angular/core';
import { LoggedUserModel } from 'src/app/store/Authentication/auth.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { GlobalComponent } from "../../global-component";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    constructor(private http: HttpClient) {
    }

    /**
     * Performs the auth
     * @param userName email of user
     * @param password password of user
     */
    login(userName: string, password: string) {
        return this.http.post(`${GlobalComponent.API_URL}auth/login`, {
            userName,
            password
        }, httpOptions).pipe(
            map((response: any) => {
                const user = response;
                return user;
            }),
            catchError((error: any) => {
                const errorMessage = 'Login failed'; // Customize the error message as needed
                return throwError(errorMessage);
            })
        );
    }

    getLoggedUser() {
        var loggedUser: LoggedUserModel;
        var currentUser = sessionStorage.getItem("currentUser");
        if (currentUser) {
            loggedUser = JSON.parse(currentUser);
            return loggedUser;
        }
        return null;
    }

    logout() {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("token");
    }

    canViewOption(permiso: string): boolean {
        return this.getLoggedUser()?.permisosSistema.find(x => x === permiso) !== undefined;
    }

}

