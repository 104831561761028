import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

// Calendar option
import { CalendarOptions, EventClickArg, EventApi, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { VwActividadPlanTrabajo } from '../../plan-trabajo/models/actividad.model';
import { ActividadGlobalService } from '../../plan-trabajo/services/actividad-global.service';
import { DashboardService } from '../services/dashboard.service';
import { VwMisTemasCapacitacion } from '../../plan-capacitacion/models/tema-principal.model';
import { GetAllAccidenteTrabajoModel } from '../../accidente-trabajo/models/accidente-trabajo.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Ecommerce Component
 */
export class DashboardComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  calendarEvents!: EventInput[];
  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      right: 'dayGridMonth,dayGridWeek',
      center: 'title',
      left: 'prev,next today'
    },
    initialView: "dayGridMonth",
    themeSystem: "bootstrap",
    initialEvents: this.calendarEvents,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    select: this.setFilterDates.bind(this),
    // eventClick: this.handleEventClick.bind(this),
    // eventsSet: this.handleEvents.bind(this),
  };

  vwActividades: VwActividadPlanTrabajo[] = [];
  vwTemas: VwMisTemasCapacitacion[] = [];
  vwAccidentes: GetAllAccidenteTrabajoModel[] = [];

  constructor(private modalService: NgbModal, private changeDetector: ChangeDetectorRef,
    private router: Router, private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService, public authService: AuthenticationService) {

  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Inicio', active: true }
    ];

    this.getMisActividades(this.authService.getLoggedUser()?.empleadoId, '', '');

    this.getMisTemas(this.authService.getLoggedUser()?.empleadoId, '', '');

    this.getAccidentes(this.authService.getLoggedUser()?.empleadoId, '', '');

  }

  getMisActividades(empleadoSesionId: any, startDate: string, endDate: string) {
    this.dashboardService.getAllActividadesByPlanTrabajo(empleadoSesionId, startDate, endDate).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.Response && event.body) {
          this.vwActividades = event.body;
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err.error)
      },
    });
  }

  getMisTemas(empleadoSesionId: any, startDate: string, endDate: string) {
    this.dashboardService.getMisTemasPrincipales(empleadoSesionId, startDate, endDate).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.Response && event.body) {
          this.vwTemas = event.body;
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err.error)
      },
    });
  }

  getAccidentes(empleadoSesionId: any, startDate: string, endDate: string) {
    this.dashboardService.getAllAccidentesTrabajo(empleadoSesionId, startDate, endDate).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.Response && event.body) {
          this.vwAccidentes = event.body;
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err.error)
      },
    });
  }

  setFilterDates(event?: any) {

    

    // this.getMisActividadesByPlanTrabajo(this.authService.getLoggedUser()?.empleadoId, event.startStr, event.endStr);

  }

}
