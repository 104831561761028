<div class="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
  <ngx-simplebar style="max-height: 300px;">
    <div class="pe-2">
      @for(notificacion of notificaciones;track $index){
      <div class="text-reset notification-item d-block dropdown-item">
        <div class="d-flex">
          <img src="assets/images/users/user-dummy-img.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
          <div class="flex-grow-1">
            <a href="javascript:void(0);" class="stretched-link">
              <h6 class="mt-0 mb-1 fs-13 fw-semibold">{{ notificacion.tituloNotificacion }}</h6>
            </a>
            <div class="fs-13 text-muted">
              <p class="mb-1"> Validar Informaci&oacute;n</p>
            </div>

            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
              <span><i class="mdi mdi-clock-outline"></i> {{tiempoTranscurrido(notificacion.fechaRegistro)}}</span>
            </p>
          </div>
          <div class="px-2 fs-15" style="z-index: 20;">
            <div class="hstack gap-3 fs-15">
              <a href="javascript:void(0);" (click)="marcarLeida(notificacion.notificacionId)"
                title="Marcar como leido" class="btn btn-outline-info btn-icon waves-effect waves-light btn-sm">
                <i class="ri-eye-line fs-5" data-bs-toggle="modal" data-bs-target="#subParameterModal"></i>
              </a>

              <a href="javascript:void(0);" (click)="irDetalle(notificacion.accidenteTrabajoId)"
                title="Ver detalle" class="btn btn-outline-info btn-icon waves-effect waves-light btn-sm">
                <i class="ri-markup-line fs-5"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      }
    </div>

    <div [ngClass]="notificaciones.length != 0 ?'d-none':''" class="tab-pane p-4" id="alerts-tab" role="tabpanel"
      aria-labelledby="alerts-tab">
      <div class="w-25 w-sm-50 pt-3 mx-auto">
        <img src="assets/images/svg/bell.svg" class="img-fluid" alt="user-pic">
      </div>
      <div class="text-center pb-5 mt-2">
        <h6 class="fs-18 fw-semibold lh-base">No tienes notificaciones </h6>
      </div>
    </div>
  </ngx-simplebar>
</div>