import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Notificaciones, EditNotificacion, NotificacionAccidenteTrabajo } from 'src/app/pages/notificaciones/models/notificaciones.model';
import { NotificacionesService } from 'src/app/pages/notificaciones/services/notificaciones.service';

@Component({
  selector: 'app-notificaciones-accidentetrabajo',
  templateUrl: './notificaciones-accidentetrabajo.component.html',
  styleUrls: ['./notificaciones-accidentetrabajo.component.css']
})
export class NotificacionesAccidentetrabajoComponent implements OnInit {

  userData: any;
  notificaciones: NotificacionAccidenteTrabajo[] = [];
  countNotificaciones: Number = 0;
  editNotificacion!: EditNotificacion;

  constructor(private authService: AuthenticationService,
    private router: Router, private tokenStorageService: TokenStorageService, private notificacionesService: NotificacionesService) { }

  ngOnInit() {
    this.userData = this.tokenStorageService.getUser();
    this.getNotificacionesData(this.userData.empleadoId);
  }

  getNotificacionesData(empleadoId: string) {
    this.notificacionesService.getNotificacionesAccidenteTrabajo(empleadoId, 'N').subscribe({
      next: (event) => {
        if (event.type === HttpEventType.Response && event.body) {
          this.notificaciones = event.body.data;
          this.countNotificaciones = event.body.total;
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err.error)
      },
    });
  }

  tiempoTranscurrido(desdeFecha: string): string {
    const ahora = new Date(); // Fecha actual
    const fecha = new Date(desdeFecha);
    // Diferencia en milisegundos
    const diferenciaMs = ahora.getTime() - fecha.getTime();

    // Cálculos precisos
    const minutos = Math.floor(diferenciaMs / (1000 * 60)); // Milisegundos a minutos
    const horas = Math.floor(diferenciaMs / (1000 * 60 * 60)); // Milisegundos a horas
    const dias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24)); // Milisegundos a días
    const semanas = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24 * 7)); // Milisegundos a semanas

    // Retornar solo el valor correspondiente según la diferencia
    if (minutos < 60) {
      return `hace ${minutos} minuto${minutos !== 1 ? 's' : ''}`;
    } else if (horas < 24) {
      return `hace ${horas} hora${horas !== 1 ? 's' : ''}`;
    } else if (dias < 7) {
      return `hace ${dias} día${dias !== 1 ? 's' : ''}`;
    } else {
      return `hace ${semanas} semana${semanas !== 1 ? 's' : ''}`;
    }
  }

  irDetalle(idnotificacion: string) {
    this.router.navigate([`accidente-trabajo/editar/${idnotificacion}`]);
  }

  marcarLeida(notificacionId: string) {

    this.editNotificacion = {
      notificacionId: notificacionId
    }

    this.notificacionesService.marcarNotificacionAccidenteTrabajoLeida(this.editNotificacion).subscribe({
      next: (event) => {
        this.getNotificacionesData(this.userData.empleadoId);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err.error)
      },
    });
    
  }


}
